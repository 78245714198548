import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Profiles from "./profile";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="About bg-gray-50">
      {/* Board of Trustees Section */}
      <div className="container mx-auto px-6 py-12 flex flex-col md:flex-row md:space-x-10 items-center">
        <div className="flex flex-col space-y-6 md:w-1/2">
          <h4 className="text-3xl font-bold text-gray-800 text-center md:text-left">
            Board of Trustees
          </h4>
          <div className="line mx-auto md:mx-0 w-24 h-1 bg-yellow-500"></div>
          <p className="text-base text-gray-700 text-center md:text-left leading-relaxed">
            Nicholasmotivates Initiative is headed by a highly esteemed board of trustees with professional humanitarian experience, focusing on empowering victimized, marginalized, and disadvantaged girls, women, and individuals with disabilities to actively participate in good menstrual hygiene management.
          </p>
        </div>
        <div className="md:w-1/2 mt-6 md:mt-0">
          <img
            className="rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 w-full"
            src="/ourteam.JPG"
            alt="Board of Trustees"
          />
        </div>
      </div>

      {/* Team Section */}
<section className="team-section bg-white py-16">
  <div className="container mx-auto px-6">
    <h3 className="text-3xl font-extrabold text-gray-800 text-center mb-8">
      Meet Our Team
    </h3>
    <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {Profiles.map((profile) => (
        <div
          key={profile.id}
          className="team-card bg-gray-100 rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition-shadow duration-300"
          data-aos="zoom-in-up"
        >
          <img
            className="rounded-full w-40 h-48 mx-auto mb-4 object-cover"
            src={profile.Icon}
            alt={profile.headtext}
          />
          <h5 className="text-xl font-bold text-gray-800 mb-2">
            {profile.headtext}
          </h5>
          <p className="text-base text-gray-600 leading-relaxed">
            {profile.bodytext}
          </p>
          <div className="mt-4 h-1 w-16 bg-yellow-500 mx-auto"></div>
        </div>
      ))}
    </div>
  </div>
</section>
    </div>
  );
};

export default About;