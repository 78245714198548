import React from 'react';

const Spinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <img
        src="/NMB.png"
        alt="Loading"
        className="animate-spin h-20 w-20"
      />
    </div>
  );
};

export default Spinner;
