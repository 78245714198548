const Profiles= [
 { id:1,
  Icon:"/founder.jpg",
  headtext:"Ugwuanyi Nicholason",
  bodytext:"Founder"
},
{ id:2,
 Icon:"/secretary.jpg",
 headtext:"Dr. Amidu James",
 bodytext:" Secretary"

},


{ id:6,
  Icon:"/prof5.png",
  headtext:"Hon Sam Adejoh Okedi",
  bodytext:" Trustee"
 
 },
 
 
 { id:7,
  Icon:"/trustee1.jpg",
  headtext:"Pharm Peter Yakubu Iduh",
  bodytext:" Trustee"
 
 },
 
 
 { id:8,
  Icon:"/prof6.png",
  headtext:"Mr.Abdulquadir Hussaini Mohammed",
  bodytext:" Trustee"
 
 },


{ id:3,
 Icon:"/prof1.png",
 headtext:"Barr. Mrs Ojochide Suleiman",
 bodytext:" Legal Counsel"

},

{ id:4,
 Icon:"/prof8.png",
 headtext:"Mr. Kabiru Muhamed",
 bodytext:" Program Manager"

},


{ id:5,
 Icon:"/profile7.jpg",
 headtext:"Dr. Charity Ugwu",
 bodytext:" Medical Team Lead"

},



{ id:9,
  Icon:"/prof9.png",
  headtext:"Dr. Esther Ezea",
  bodytext:"Program Developer"
 
 },


 { id:10,
  Icon:"/comms.jpg",
  headtext:"Mr Ameh Gideon",
  bodytext:"Communication Manager"
 
 },
 
 
 
 { id:11,
   Icon:"/data.jpg",
   headtext:"Eze Chukwunonso Chuka",
   bodytext:"Data Analyst"
  
  },
 




]


export default Profiles ;